export function priceAfterDiscount(
  originalPrice: number,
  discount_value: number,
  isFixed: boolean
): number {
  let price: number;
  if (isFixed) {
    price = originalPrice - discount_value;
  } else {
    price = Math.round(originalPrice - originalPrice * (discount_value / 100));
  }

  return price;
}
