import { ApolloProvider } from "@apollo/client";
import "styles/global.css";
import "draft-js/dist/Draft.css";
import "draft-js/dist/Draft.css";
import "react-datepicker/dist/react-datepicker.css";
import { useApollo } from "lib/hooks/apollo-client";
import "fontsource-raleway";
import "fontsource-abril-fatface";
import { AppProps } from "next/app";
import { BasketProvider } from "lib/hooks/basket/use-basket";
import { useEffect } from "react";
import { initializeBasket } from "lib/basket/initialize";
import { basketReducer } from "lib/basket/reducer";
import { useUser } from "lib/hooks/use-user";

export function reportWebVitals(metric) {
  if (process.env.NODE_ENV === "development") {
    console.log(metric);
    return;
  }
  return;
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const client = useApollo(pageProps);
  let basket = initializeBasket();
  const { userData } = useUser();

  useEffect(() => {}, []);

  return (
    <ApolloProvider client={client}>
      <BasketProvider initialState={basket} reducer={basketReducer}>
        <Component {...pageProps} />
      </BasketProvider>
    </ApolloProvider>
  );
}
