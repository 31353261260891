import { basketReducer } from 'lib/basket/reducer';
import { IBasket, IBasketAction, IBasketActionType } from 'lib/basket/types';
import { createContext, Dispatch, useContext, useReducer } from 'react';

export const BasketContext =
  createContext<[IBasket, Dispatch<IBasketAction<IBasketActionType>>]>(null);

interface BasketProviderProps {
  initialState: IBasket;
  reducer: typeof basketReducer;
  children;
}

export const BasketProvider = ({
  children,
  initialState,
  reducer,
}: BasketProviderProps) => (
  <BasketContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </BasketContext.Provider>
);

export const useBasket = () => useContext(BasketContext);
