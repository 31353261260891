import { useMemo } from "react";
import {
  ApolloClient,
  InMemoryCache,
  from,
  Observable,
  HttpLink,
  ApolloLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { HASURA_ENDPOINT } from "../constants";
import { onError } from "@apollo/client/link/error";
import { AppProps } from "next/app";
import { Redirect } from "next/dist/lib/load-custom-routes";

let apolloClient;
export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

function createApolloClient() {
  const isServer = typeof window === "undefined";

  const cache = new InMemoryCache({
    typePolicies: {
      order_subscription_price: {
        keyFields: ["order_id", "sub_price_id"],
      },
    },
  });

  const httpLink = new HttpLink({
    uri: HASURA_ENDPOINT,
    fetch: fetch,
  });

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      if (isServer) {
        return {
          headers: {
            ...headers,
            "x-hasura-admin-secret": process.env.HASURA_GRAPHQL_ADMIN_SECRET,
          },
        };
      } else {
        const token = localStorage.getItem("jwt");

        if (token) {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          };
        } else {
          return {
            headers: {
              ...headers,
            },
          };
        }
      }
    });

    return forward(operation);
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          console.log("apollo-error", { err, operation });

          switch (err.extensions.code) {
            case "invalid-jwt":
              return new Observable((observer) => {
                // console.log('refreshing JWT');
                fetch("/api/auth/user", {
                  method: "GET",
                  credentials: "include",
                })
                  .then((r) => r.json())
                  .then((data) => {
                    if (data.jwt) {
                      localStorage.setItem("jwt", data.jwt);
                    } else if (data.error) {
                      localStorage.removeItem("jwt");
                      window?.location.assign("/signin");
                    }

                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    // Retry last failed request
                    forward(operation).subscribe(subscriber);
                  })
                  .catch((e) => {
                    console.log(e);
                    observer.error(e);
                  });
              });
          }
        }
      }
    }
  );

  return new ApolloClient({
    ssrMode: isServer,
    link: from([errorLink, authLink, httpLink]),
    cache: cache,
    credentials: "include",
  });
}

export function initializeApollo(
  initialState = null
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

type P = { [key: string]: any };

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: {
    props: object;
    redirect?: Redirect;
    notFound?: true;
  } = {
    props: {},
  }
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: AppProps["pageProps"]) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
