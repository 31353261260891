import { RawDraftContentState } from "draft-js";
import { bookIsDiscounted } from "./discount/book-is-discounted";
import { Orders } from "./graphql-generated";
import { priceAfterDiscount } from "./price-after-discount";

export function isNumber(value) {
  return /^\d+$/.test(value);
}

export function range(start: number, end?: number, step: number = 1): number[] {
  let output = [];

  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }

  for (let i = start; i < end; i += step) {
    output.push(i);
  }

  return output;
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

export const getToken = () => {
  const token = localStorage.getItem("jwt");
  return token;
};

export const parseContentForBooks = (content: string): number[] => {
  const rawContent: RawDraftContentState = JSON.parse(content);
  const entityRanges = rawContent.blocks.flatMap((b) => b.entityRanges);
  let books = [];
  for (let index = 0; index < entityRanges.length; index++) {
    const entityKey = entityRanges[index];
    const entity = rawContent.entityMap[entityKey.key];
    try {
      if (parseLinkType(entity.data.url) === "book") {
        const urlParts = entity.data.url.split("/");

        books = [...books, parseInt(urlParts[urlParts.length - 1])];
      }
    } catch (e) {
      // do nothing
    }
  }
  return books;
};

export function parseParam(param: string | string[]) {
  return Array.isArray(param) ? param[0] : param;
}

export const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  })
    .format(date)
    .replace(/\//g, ".");
};

export const formatTime = (date: Date) => {
  return new Intl.DateTimeFormat("en-GB", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  }).format(date);
};

export const DateObject = (dateString: string) => {
  let year: string;
  let month: string;
  let day: string;
  let hour: string;
  let minute: string;

  const dateParts = dateString.split("T");
  const date = dateParts[0].split("-");
  const time = dateParts[1].split(":");

  year = date[0];
  month = date[1];
  day = date[2];
  hour = time[0];
  minute = time[1];

  return {
    year,
    month,
    day,
    hour,
    minute,
  };
};

export const getOrderTotal = (order: Partial<Orders>) => {
  const hasDiscountObject = !!order.discount;

  const booksTotal =
    order.order_books?.reduce((acc, curr) => {
      if (
        curr.discount === 0 &&
        hasDiscountObject &&
        bookIsDiscounted(curr.book?.isbn || curr.book_isbn, order.discount)
      ) {
        return (
          acc +
          priceAfterDiscount(
            curr.price,
            order.discount.discount_value,
            order.discount.is_fixed_amount
          ) *
            curr.quantity
        );
      } else {
        return acc + (curr.price - curr.discount) * curr.quantity;
      }
    }, 0) ?? 0;

  const productsTotal =
    order.order_products?.reduce(
      (acc, curr) => acc + (curr.price - curr.discount) * curr.quantity,
      0
    ) ?? 0;

  const subsTotal =
    order.subscription_objects?.reduce(
      (acc, curr) =>
        acc +
        (curr.subscription.price * curr.subscription_draft_object?.credits ||
          0),
      0
    ) ?? 0;

  const voucherTotal =
    order.vouchers?.reduce((acc, curr) => acc + curr.initial_amount, 0) ?? 0;

  const shipping = order.custom_shipping_price
    ? order.custom_shipping_price
    : order.shipping_option?.price ?? 0;
  const customTotal = order.custom_line_price ?? 0;
  const discountVouchers =
    order.order_vouchers_for_discount?.reduce(
      (acc, curr) => acc + curr.amount_discounted,
      0
    ) ?? 0;

  const pif =
    order.pif_donations?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;

  const wrapping = order.wrapping ?? 0;

  const total =
    booksTotal +
    productsTotal +
    subsTotal +
    voucherTotal +
    shipping +
    customTotal +
    pif +
    wrapping -
    discountVouchers;

  return total;
};

type LinkType = "link" | "event" | "book";
export function parseLinkType(link: string): LinkType {
  if (link.charAt(0) === "/") {
    if (link.split("/")[1] === "event") return "event";
    if (link.split("/")[1] === "book") return "book";
  }

  return "link";
}
