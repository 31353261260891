import useSWR from 'swr';
import { createContext, ReactNode, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { JwtData, UserError } from '../types';
import { Roles_Enum } from 'lib/graphql-generated';

async function fetcher<JSON = any>(input): Promise<JSON> {
  const token = localStorage.getItem('jwt');
  const res = await fetch(input, {
    method: 'POST',
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return res.json();
}

interface Props {
  redirectTo?: string;
  redirectIfValid?: string;
}

export const useUser = (
  role?: Roles_Enum,
  { redirectTo, redirectIfValid }: Props = {
    redirectTo: null,
    redirectIfValid: null,
  }
): { loadingUser: boolean; userData?: JwtData; userError?: UserError } => {
  const { data, error } = useSWR<{
    user?: JwtData;
    jwt?: string;
    error?: UserError;
  }>('/api/auth/user', fetcher);

  const user = data?.user;
  const finished = Boolean(data);
  const hasUser = Boolean(user);
  const router = useRouter();

  useEffect(() => {
    const locationIsNotRedirect = window?.location.pathname !== redirectTo;
    const locationIsNotRedirectIfValid =
      window?.location.pathname !== redirectIfValid;

    if (!finished) return;

    // If user exists, set the JWT to storage (updates existing if exists)
    if (data.jwt) {
      localStorage.setItem('jwt', data.jwt);
    }

    // If server cannot find user, but some credentials are still present
    // remove those credentials
    if (data.error && data.error.removeCredentials) {
      localStorage.removeItem('jwt');
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (!hasUser && redirectTo) ||
      // If user does not fulfill Role criteria, redirect
      (hasUser &&
        role &&
        user.role !== role &&
        redirectTo &&
        locationIsNotRedirect)
    ) {
      router.push(redirectTo);
    }

    if (
      // If redirectIfFound is also set, redirect if the user was found
      redirectIfValid &&
      hasUser &&
      (!role || user.role === role) &&
      locationIsNotRedirectIfValid
    ) {
      router.push(redirectIfValid);
    }
  }, [redirectTo, redirectIfValid, finished, hasUser]);

  const res = {
    loadingUser: !finished,
    userData: user,
    userError: data?.error || error,
  };
  return res;
};
