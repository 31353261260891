import { Book_Discount, Discounts } from 'lib/graphql-generated';
import { isSubsetOf } from 'lib/sets';
import { DeepPartial } from 'types';

export const bookIsDiscounted = (
  isbn: any,
  discountObject: DeepPartial<Discounts>
) => {
  const keys = Object.keys(discountObject);
  const requiredKeys: Array<keyof Discounts> = [
    'discount_value',
    'is_fixed_amount',
    'discounted_books',
  ];

  if (!isSubsetOf(keys, requiredKeys)) {
    throw new Error('Discount object is missing some parameters');
  }

  if (discountObject.discounted_books.length === 0) {
    return true;
  } else if (
    discountObject.discounted_books.some((book) => {
      return book.book_isbn == isbn;
    })
  ) {
    return true;
  } else {
    return false;
  }
};
