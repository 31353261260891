export const HASURA_ENDPOINT = process.env.NEXT_PUBLIC_HASURA_GRAPHQL_ENDPOINT;
export const JWT_SECRET = process.env.JWT_SECRET;
export const REFRESH_SECRET = process.env.REFRESH_SECRET;

type Env = 'production' | 'staging' | 'development' | 'review';
export const ENV: Env = (process.env.NODE_ENV as Env) || 'development';
export let URL_PREFIX: string;
switch (ENV) {
  case 'production':
    URL_PREFIX = 'https://lighthousebookshop.com';
    break;
  case 'development':
    URL_PREFIX = 'http://localhost:3000';
    break;
}

export const STRIPE_SUCCESS_URL = `${URL_PREFIX}/thank-you?session_id={CHECKOUT_SESSION_ID}`;
export const STRIPE_WH_SECRET = process.env.STRIPE_WEBHOOK_SECRET;

export const INVALID_ORDER = 'INVALID_ORDER';
export const PICKUP_SHIPPING_ID = '00000000-0000-0000-0000-000000000000';
export const NO_DELIVERABLES_SHIPPING_ID =
  '11111111-1111-1111-1111-111111111111';
