import { isNumber } from 'lib/utils';
import { IBasket, IBasketItem } from './types';

export const emptyBasket: IBasket = {
  address: null,
  shippingOptionId: null,
  items: [],
  discount_vouchers: [],
  discount: null,
  wrapping: null,
  pif: null,
  returnUrl: null,
  note: '',
};

export const initializeBasket = () => {
  let possibleBasket: IBasket = emptyBasket;

  if (typeof window !== 'undefined') {
    try {
      let tempBasket: IBasket = JSON.parse(localStorage.getItem('basket'));
      let keys = Object.keys(tempBasket);
      if (
        keys.length !== Object.keys(possibleBasket).length ||
        !(
          keys.includes('address') &&
          keys.includes('shippingOptionId') &&
          keys.includes('items') &&
          keys.includes('wrapping') &&
          keys.includes('pif')
        )
      ) {
        throw new Error();
      }

      tempBasket.items = tempBasket.items.filter((item) => {
        if (item.type === 'book') {
          let i = item as IBasketItem<'book'>;
          return (
            i &&
            isNumber(i.data.price) &&
            isNumber(i.data.quantity) &&
            !!i.data.book.isbn
          );
        } else if (item.type === 'product') {
          let i = item as IBasketItem<'product'>;
          return (
            i &&
            isNumber(i.data.price) &&
            isNumber(i.data.quantity) &&
            !!i.data.product.id
          );
        } else if (item.type === 'ticket-book') {
          let i = item as IBasketItem<'ticket-book'>;
          return (
            item &&
            isNumber(i.data.price) &&
            isNumber(i.data.quantity) &&
            !!i.data.book.isbn &&
            !!i.data.event
          );
        } else if (item.type === 'ticket-voucher') {
          let i = item as IBasketItem<'ticket-voucher'>;
          return i && isNumber(i.data.price) && !!i.data.event;
        } else {
          return item;
        }
      });

      possibleBasket = tempBasket;
    } catch (e) {
      localStorage.removeItem('basket');
    }
  }

  return possibleBasket;
};
