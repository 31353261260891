import { emptyBasket } from "./initialize";
import {
  IBasket,
  IBasketAction,
  IBasketActionType,
  IBasketItem,
} from "./types";

function handleAdd(state: IBasket, action: IBasketAction<"add">): IBasket {
  let newState: IBasket;

  newState = {
    ...state,
    items: state.items.some((item) => item.id === action.payload.id)
      ? state.items.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              data: {
                ...item.data,
                quantity: Math.max(
                  item.data.quantity + action.payload.data.quantity,
                  0
                ),
              },
            };
          } else {
            return item;
          }
        })
      : [...state.items, action.payload],
  };

  if (newState.items.length === 0) {
    newState = {
      ...newState,
      wrapping: null,
    };
  }

  return newState;
}

function handleRemove(
  state: IBasket,
  action: IBasketAction<"remove">
): IBasket {
  let newState: IBasket;
  const itemIsZeroOrLess =
    state.items.find((item) => item.id === action.payload.id).data.quantity -
      action.payload.data.quantity <=
    0;

  if (itemIsZeroOrLess) {
    newState = {
      ...state,
      items: state.items.filter((item) => item.id !== action.payload.id),
    };
  } else {
    newState = {
      ...state,
      items: state.items.map((item) =>
        item.id === action.payload.id
          ? {
              ...item,
              data: {
                ...item.data,
                quantity: item.data.quantity - action.payload.data.quantity,
              },
            }
          : item
      ),
    };
  }

  if (newState.items.length === 0) {
    newState = {
      ...newState,
      wrapping: null,
    };
  }

  return newState;
}

function handleAdjust(
  state: IBasket,
  action: IBasketAction<"adjust">
): IBasket {
  let newState: IBasket;
  const item = state.items.find((item) => item.id === action.payload.id);
  const itemIsZeroOrLess = item && action.payload.data.quantity <= 0;

  if (!!item && itemIsZeroOrLess) {
    newState = {
      ...state,
      items: state.items.filter((item) => item.id !== action.payload.id),
    };
  } else if (!!item && !itemIsZeroOrLess) {
    newState = {
      ...state,
      items: state.items.map((item) =>
        item.id === action.payload.id ? action.payload : item
      ),
    };
  } else {
    newState = state;
  }

  if (newState.items.length === 0) {
    newState = {
      ...newState,
      wrapping: null,
    };
  }

  return newState;
}

function handleEmpty(state: IBasket, action: IBasketAction<"empty">): IBasket {
  let newState: IBasket = emptyBasket;

  return newState;
}

function handleModifyPif(
  state: IBasket,
  action: IBasketAction<"modify-pif">
): IBasket {
  let newState: IBasket = {
    ...state,
    pif: action.payload,
  };

  return newState;
}

function handleSetAddress(
  state: IBasket,
  action: IBasketAction<"set-address">
): IBasket {
  let newState: IBasket = {
    ...state,
    address: action.payload,
  };
  return newState;
}

function handleSetShipping(
  state: IBasket,
  action: IBasketAction<"set-shipping">
): IBasket {
  let newState: IBasket = {
    ...state,
    shippingOptionId: action.payload,
  };

  return newState;
}

function handleToggleWrapping(
  state: IBasket,
  action: IBasketAction<"toggle-wrapping">
): IBasket {
  let newState: IBasket = {
    ...state,
    wrapping: action.payload,
  };

  return newState;
}

function handleAddVoucher(
  state: IBasket,
  action: IBasketAction<"add-voucher">
): IBasket {
  let newState: IBasket = {
    ...state,
    discount_vouchers: state.discount_vouchers.find(
      (v) => v.id === action.payload.id
    )
      ? state.discount_vouchers
      : [...state.discount_vouchers, action.payload],
  };

  return newState;
}

function handleRemoveVoucher(
  state: IBasket,
  action: IBasketAction<"remove-voucher">
): IBasket {
  let newState: IBasket = {
    ...state,
    discount_vouchers: state.discount_vouchers.filter(
      (v) => v.id !== action.payload.id
    ),
  };

  return newState;
}

function handleAddDiscount(
  state: IBasket,
  action: IBasketAction<"add-discount">
): IBasket {
  const discount = action.payload;

  let newState: IBasket = {
    ...state,
    discount: discount,
  };

  return newState;
}

function handleRemoveDiscount(state: IBasket): IBasket {
  let newState: IBasket = {
    ...state,
    discount: null,
  };

  return newState;
}

function handleAddNote(
  state: IBasket,
  action: IBasketAction<"add-note">
): IBasket {
  let newState: IBasket = {
    ...state,
    note: action.payload,
  };

  return newState;
}

export const basketReducer = (
  state: IBasket,
  action: IBasketAction<IBasketActionType>
): IBasket => {
  let newState: IBasket;

  switch (action.type) {
    case "add":
      newState = handleAdd(state, action);
      break;
    case "remove":
      newState = handleRemove(state, action);
      break;
    case "add-voucher":
      newState = handleAddVoucher(state, action);
      break;
    case "remove-voucher":
      newState = handleRemoveVoucher(state, action);
      break;
    case "add-discount":
      newState = handleAddDiscount(state, action);
      break;
    case "remove-discount":
      newState = handleRemoveDiscount(state);
      break;
    case "adjust":
      newState = handleAdjust(state, action);
      break;
    case "empty":
      newState = handleEmpty(state, action);
      break;
    case "modify-pif":
      newState = handleModifyPif(state, action);
      break;
    case "set-address":
      newState = handleSetAddress(state, action);
      break;
    case "set-shipping":
      newState = handleSetShipping(state, action);
      break;
    case "toggle-wrapping":
      newState = handleToggleWrapping(state, action);
      break;
    case "add-note":
      newState = handleAddNote(state, action);
      break;
  }

  localStorage.setItem("basket", JSON.stringify(newState));
  return newState;
};
